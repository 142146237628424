import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import styled from "styled-components";
import { setAuthModal, setAuthRoute } from "../../slices/auth";
import { useDispatch, useSelector } from "../../store";
import Modal from "../base/Modal";
import Footer from "../views/Footer";
import ForgotPasswordView from "../views/Auth/ForgotPasswordView";
import LoginView from "../views/Auth/LoginView";
import MainNavbar from "../views/MainNavbar";
import RegisterView from "../views/Auth/RegisterView";
import ResetPasswordView from "../views/Auth/ResetPasswordView";
import ChangePassword from "../views/ChangePassword";
import { handleErrorToastState, setErrorMessage } from "../../slices/toast";
import { useMediaQuery } from "@mui/material";
import theme from "../../theme";
import { setResetDogFilterBool } from "../../slices/filters";

interface Props {
  xs?: string;
}

const Root = styled.div<Props>`
  display: flex;
  height: 100%;
  width: 100%;
`;

const Wrapper = styled.div<Props>`
  display: flex;
  flex: 1 1 auto;
  overflow: hidden;
  position: relative;
  padding-bottom: 62px;
`;

const Background = styled.div`
  background-image: url(${"/static/images/metal.jpg"});
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  display: flex;
  height: 100%;
  width: 100%;
  opacity: 0.1;
  position: absolute;
`;

const Container = styled.div`
  display: flex;
  flex: 1 1 auto;
  overflow: hidden;
  position: relative;
`;

const Content = styled.div<Props>`
  flex: 1 1 auto;
  height: 100%;
  overflow: auto;
  position: relative;
  -webkit-overflow-scrolling: touch;
  padding-top: ${(props) => props.xs};
`;

const MainLayout: React.FC<Props> = (props) => {
  const { authModal, authRoute, loggedInUser } = useSelector((store) => store.auth);
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [reroute, setReroute] = useState("");
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  const { resetDogFilterBool } = useSelector((store) => store.filters);
  const location = useLocation();

  useEffect(() => {
    if (!resetDogFilterBool) {
      dispatch(setResetDogFilterBool(true));
    }
  }, [location.pathname]);

  const closeModal = () => {
    dispatch(setAuthModal(false));
  };

  useEffect(() => {
    if (state) {
      if (state.loginForRedirect && !loggedInUser) {
        dispatch(setErrorMessage("You must be logged in to view this"));
        dispatch(handleErrorToastState(true));
        dispatch(setAuthRoute("login"));
        dispatch(setAuthModal(true));
      }
      if (state.prevPath) {
        setReroute(state.prevPath);
      }
    }
  }, [state, dispatch]);

  return (
    <Root>
      <MainNavbar />
      <Wrapper>
        <Background />
        <Container>
          <Content xs={sm ? "85px" : "105px"}>
            <Outlet />
          </Content>
        </Container>
      </Wrapper>
      {authModal ? null : <Footer includeDonate={true} />}
      <Modal open={authModal} onClose={closeModal}>
        {authRoute === "register" ? (
          <RegisterView />
        ) : authRoute === "forgot" ? (
          <ForgotPasswordView />
        ) : authRoute === "reset" ? (
          <ResetPasswordView />
        ) : authRoute === "login" ? (
          <LoginView reroute={reroute} setReroute={setReroute} />
        ) : authRoute === "change" ? (
          <ChangePassword />
        ) : (
          <></>
        )}
      </Modal>
    </Root>
  );
};

export default MainLayout;
